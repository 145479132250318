module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-180181911-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Culinary Cloud","short_name":"My Culinary Cloud","start_url":"/","background_color":"#ffffff","theme_color":"#Eb4A36","display":"minimal-ui","icon":"content/assets/my-culinary-cloud-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1ed3dfe4d711bc9569044eb90ba64e69"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
